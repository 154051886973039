import photoc_1 from './img/f0046abc-5b01-4179-9826-9fc36607255d.jpg';
import photoc_2 from './img/596ce5ac-e34b-4bde-bb94-12c423a8d209.jpg';

function OpeningsTijden() {
    return (
      <div className='outlet-content'>
        
        <h2>Openingstijden</h2>

        <div>maandag 07.30 - 18.00 uur</div>
        <div>dinsdag 07.30 - 18.00 uur</div>
        <div>donderdag 07.30 - 18.00 uur</div>
        <br />
        <div>Als gastouder vang ik maximaal 5 kindjes onder de 4 jaar op. Hiervan zijn er maximaal 2 baby.</div>
        <br />
        <div  class='text-block'>
        Voor een gezonde lunch, tussendoortjes en voldoende drinken wordt gezorgd. 
        Het is fijn als jullie zelf zorgen voor moedermelk/kunstvoeding, luiers, billendoekjes en eventueel andere voeding ivm specifieke wensen.
        </div>
        <h2>Uurtarief</h2>
        <div>Mijn uurtarief is €7.60</div>
        <div>Ik heb een LRK nummer, er kan dus kinderopvangtoeslag aangevraagd worden.</div>
        <br />
        <div className='photo-h'>
            <img className='photo-style-1' src={photoc_1} alt="" />
            <img className='photo-style-1' src={photoc_2} alt="" />
        </div>
        <br />

        <br />


      </div>
    );
  }
  
  export default OpeningsTijden;
  